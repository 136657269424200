var Sidebar = {
  open: function() {
    $('#Sidebar').show();
    return $('#SidebarOverlay').show();
  },
  close: function() {
    $('#Sidebar').hide();
    return $('#SidebarOverlay').hide();
  }
};

$(function() {
  $('.open_sidebar').click(function(e) {e.preventDefault();  Sidebar.open();});
  $('.close_sidebar, #SidebarOverlay').click(function(e) {e.preventDefault();  Sidebar.close();});
});