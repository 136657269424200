init = function() {
  $('.disclosable').each( function(index, element) {
    if (!$(element).data('disclosable-enabled')) {
      $(element).find('.visible').prepend("<div class='icon'><span class='i'><i class='fas fa-caret-right'></i></span></div>")
                .click( click );
      $(element).find('.icon').prepend($(element).find('.quantity'));
      setIcon($(element));

      $(element).data('disclosable-enabled', true);
    }
  });
}

click = function() {
  var root = $(this).parent();
  root.hasClass('open') ? root.removeClass('open') : root.addClass('open');
  setIcon(root);
}

setIcon = function(element) {
  if (element.hasClass('open')) {
    element.find('.icon .i').html("<i class='fas fa-caret-down'></i>");
  } else {
    element.find('.icon .i').html("<i class='fas fa-caret-right'></i>");
  }
}


$().ready(function() {
  init();
});
