function closeFlash(e) {
  if (e) {e.preventDefault();}
  $('#Flash').slideUp();
}

$(function() {
  if ($('#Flash .content').html().trim() !== '') {
    $('#Flash .close_flash').click(closeFlash);
    $('#Flash').slideDown();
    setTimeout(closeFlash, 5000);
  }
});
