/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


import Rails from "@rails/ujs"
Rails.start()
// import Turbolinks from "turbolinks"
// Turbolinks.start()
// import "channels"
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import 'core-js/stable'
import 'regenerator-runtime/runtime'

require("trix")
require("@rails/actiontext")
require("packs/richtext")

// require("@rails/ujs").start()

import 'w3-css/w3.css'

import "@fortawesome/fontawesome-free/js/all"
import "@fortawesome/fontawesome-free/css/all"

require('../src/javascript/common/disclosable.js')
import '../src/javascript/common/disclosable.scss'

require('../src/javascript/common/buttons.js')
require('../src/javascript/common/clipboard.js')
require('../src/javascript/common/flash.js')
require('../src/javascript/common/sidebar.js')
